require("./bootstrap");
const ClassicEditor = require("@ckeditor/ckeditor5-build-classic");

// BEGIN - Datepicker config
$.fn.datepicker.dates["es"] = {
    days: [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado"
    ],
    daysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    daysMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
    months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
    ],
    monthsShort: [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic"
    ],
    today: "Hoy",
    clear: "Vaciar",
    language: "es"
};

$(".datepicker").datepicker({
    language: "es",
    todayHighlight: true,
    format: "dd-mm-yyyy",
    weekStart: 1
});
// END - Datepicker config

// BEGIN - CKEditor config
ClassicEditor.create(document.querySelector("#comments"), {
    toolbar: [
        "bold",
        "italic",
        "|",
        "numberedList",
        "bulletedList",
        "|",
        "outdent",
        "indent",
        "link"
    ]
}).catch(e => {
    console.log(e);
});
// END - CKEditor config

// BEGIN - Select2 config
$(".select2").select2({
    dropdownParent: $("#createPatient"),
    theme: "bootstrap4",
    language: "es"
});

$("#veterinary2").select2({
    dropdownParent: $("#createAppointment"),
    theme: "bootstrap4",
    language: "es"
});

$("#veterinary").select2({
    theme: "bootstrap4",
    language: "es"
});
// END - Select2 config

$(".custom-file-input").on("change", function() {
    var fileName = $(this)
        .val()
        .split("\\")
        .pop();
    $(this)
        .siblings(".custom-file-label")
        .addClass("selected")
        .html(fileName);
});
